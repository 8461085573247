import React, { Component } from "react"
import PropTypes from "prop-types"

import "./styles.scss"

class Switch extends Component {
    constructor(props) {
        super(props)
        this.onEnabled = props.onEnabled
        this.onDisabled = props.onDisabled
        this.title = props.title
        this.isActive = props.isEnabled
        this.state = {}
        this.state.isEnabled = props.isEnabled
        this.handleClick = this.handleClick.bind(this)

        if (props.isEnabled) {
            this.state.class = "switch-enabled"
            this.state.aria = "true"
        } else {
            this.state.class = "switch-disabled"
            this.state.aria = "false"
        }
    }

    handleClick() {
        const newState = !this.state.isEnabled
        this.setState({ isEnabled: newState })

        if (newState) {
            this.setState({
                class: "switch-enabled",
                aria: "true"
            })
            this.onEnabled()
        } else {
            this.setState({
                class: "switch-disabled",
                aria: "false"
            })
            this.onDisabled()
        }
    }

    render() {
        return (
            <div className={`switch ${ this.state.class }`} aria-label={this.title} title={this.title} role="checkbox" aria-checked={this.state.aria} onClick={this.handleClick} onKeyDown={this.handleClick} tabIndex={0} />
        )
    }
}

Switch.defaultProps = {
    onEnabled: () => { return true },
    onDisabled: () => { return true }
}

Switch.propTypes = {
    onEnabled: PropTypes.func,
    onDisabled: PropTypes.func,
    isEnabled: PropTypes.bool,
    title: PropTypes.string.isRequired
}
  
export default Switch