import React, { Component } from "react"
import ReactDOM from "react-dom"

import "./styles.scss"

class Modal extends Component {
    constructor(props) {
        super(props)
        this.children = props.children
        this.disableClose = (props.disableClose === true ? true : false)
        if (typeof document !== "undefined") {
            this.modalRoot = document.getElementById("overlay-root")
        } else {
            this.modalRoot = null
        }
        this.state = {
            open: true,
            class: ""
        }
        this.headline = props.headline
        this.closeCallback = props.closeCallback
        this.closeModal = this.closeModal.bind(this)
    }


    componentDidMount() {
        if (this.state.open) {
            const ref = this
            setTimeout(function() { ref.setState({ class: 'is-open' }) }, 50)
        }

        if (this.props.closeModal) {
            this.closeModal()
        }
    }

    closeModal() {
        this.setState({ class: '' })
        const ref = this
        setTimeout(function() { 
            ref.setState({
                open: false
            })
            ref.closeCallback()
        }, 400)
    }

    render() {
        if (typeof window !== "undefined") {
            return ReactDOM.createPortal(
                <div>
                    {this.state.open && 
                    <div className={`modal ${ this.state.class }`}>
                        <div className="modal-box" role="dialog">
                            <div className="controls">
                                <span className="h3">{this.headline}</span>
                                {!this.disableClose &&
                                    <span className="material-icons" onClick={this.closeModal} onKeyDown={this.onClick} role="button" tabIndex={0}>close</span>
                                }
                            </div>
                            <div className="content">
                                {this.props.children}
                            </div>
                        </div>
                        {this.disableClose ? <div className="close-trigger-disabled"></div> : <div className="close-trigger" onClick={this.closeModal} onKeyDown={this.closeModal} tabIndex={0} role="button">Overlay schließen</div>}
                    </div>}
                </div>,
                this.modalRoot
            );
        } else {
            return <div></div>
        }
    }
}  
  
export default Modal