import React, { Component } from "react"
import { withCookies } from "react-cookie"
import { connect } from "react-redux"
import { enableMarketingCookies, enableEmbedCookies, enableTrackingCookies, disableMarketingCookies, disableEmbedCookies, disableTrackingCookies, hasUnsafedCookieChanges, hasNoUnsafedCookieChanges, enableBasicCookies, disableBasicCookies } from "../../utils/store/actions"
import Modal from "../Modal"
import Button from "../Button"
import Switch from "../Switch"

import "./styles.scss"

class CookieConsent extends Component {
    constructor(props) {
        super(props)
        this.cookies = props.cookies

        this.acceptAllCookies = this.acceptAllCookies.bind(this)
        this.changeMarketingCookies = this.changeMarketingCookies.bind(this)
        this.changeTrackingCookies = this.changeTrackingCookies.bind(this)
        this.changeEmbedCookies = this.changeEmbedCookies.bind(this)
        this.disableAllCookies = this.disableAllCookies.bind(this)
        this.saveConsent = this.saveConsent.bind(this)
        this.selectCookies = this.selectCookies.bind(this)

        this.onAcceptTracking = this.props.onAcceptTracking
        this.onAcceptMarketing = this.props.onAcceptMarketing

        this.state = {
            hasShownCookieConsent: (this.props.cookies.get("presentedCookieConsent") === "true" ? true : false),
            allowBasicCookies: (this.props.cookies.get("allowBasicCookies") === "true" ? true : false),
            allowMarketingCookies: (this.props.cookies.get("allowMarketingCookies") === "true" ? true : false),
            allowTrackingCookies: (this.props.cookies.get("allowTrackingCookies") === "true" ? true : false),
            allowEmbedCookies: (this.props.cookies.get("allowEmbedCookies") === "true" ? true : false),
            editCookies: false,
            needAppearance: true
        }

        if (this.state.allowBasicCookies) {
            this.props.enableBasicCookies()
        } else {
            this.props.disableBasicCookies()
        }

        if (this.state.allowMarketingCookies) {
            this.props.enableMarketingCookies()
            this.onAcceptMarketing()
        } else {
            this.props.disableMarketingCookies()
        }

        if (this.state.allowTrackingCookies) {
            this.props.enableTrackingCookies()
            this.onAcceptTracking()
        } else {
            this.props.disableTrackingCookies()
        }

        if (this.state.allowEmbedCookies) {
            this.props.enableEmbedCookies()
        } else {
            this.props.disableEmbedCookies()
        }
    }
    
    selectCookies() {
        this.setState(state => ({ editCookies: true, needAppearance: false }))
    }

    acceptAllCookies() {
        this.setState(state => ({ allowMarketingCookies: true }))
        this.props.enableMarketingCookies()
        this.onAcceptMarketing()
        this.setState(state => ({ allowTrackingCookies: true }))
        this.props.enableTrackingCookies()
        this.onAcceptTracking()
        this.setState(state => ({ allowEmbedCookies: true }))
        this.props.enableEmbedCookies()
        this.setState(state => ({ allowBasicCookies: true }))
        this.props.enableBasicCookies()

        this.cookies.set("allowBasicCookies", true, { path: "/", maxAge: 1209600 })
        this.cookies.set("allowMarketingCookies", true, { path: "/", maxAge: 1209600 })
        this.cookies.set("allowTrackingCookies", true, { path: "/", maxAge: 1209600 })
        this.cookies.set("allowEmbedCookies", true, { path: "/", maxAge: 1209600 })
        this.cookies.set("presentedCookieConsent", true, { path: "/", maxAge: 1209600 })

        this.setState({ hasShownCookieConsent: true })
    }

    disableAllCookies() {
        this.setState({ allowMarketingCookies: false })
        this.props.disableMarketingCookies()
        this.setState({ allowTrackingCookies: false })
        this.props.disableTrackingCookies()
        this.setState({ allowEmbedCookies: false })
        this.props.disableEmbedCookies()
        this.setState({ allowBasicCookies: false })
        this.props.disableBasicCookies()

        this.cookies.remove("allowBasicCookies")
        this.cookies.remove("allowMarketingCookies")
        this.cookies.remove("allowTrackingCookies")
        this.cookies.remove("allowEmbedCookies")
        this.cookies.remove("presentedCookieConsent")

        this.setState({ hasShownCookieConsent: true })
    }

    changeMarketingCookies() {
        const newState = !this.state.allowMarketingCookies
        this.setState({ allowMarketingCookies: newState })
        if (newState) {
            this.props.enableMarketingCookies()
        } else {
            this.props.disableMarketingCookies()
        }
        this.props.hasUnsafedCookieChanges()
    }

    changeTrackingCookies() {
        const newState = !this.state.allowTrackingCookies
        this.setState({ allowTrackingCookies: newState })
        if (newState) {
            this.props.enableTrackingCookies()
        } else {
            this.props.disableTrackingCookies()
        }
        this.props.hasUnsafedCookieChanges()
    }

    changeEmbedCookies() {
        const newState = !this.state.allowEmbedCookies
        this.setState({ allowEmbedCookies: newState })
        if (newState) {
            this.props.enableEmbedCookies()
        } else {
            this.props.disableEmbedCookies()
        }
        this.props.hasUnsafedCookieChanges()
    }

    saveConsent() {
        this.cookies.set("allowBasicCookies", true, { path: "/", maxAge: 1209600 })
        this.props.enableBasicCookies()
        this.cookies.set("allowMarketingCookies", this.state.allowMarketingCookies, { path: "/", maxAge: 1209600 })
        if (this.state.allowMarketingCookies) {
            this.onAcceptMarketing()
        }
        this.cookies.set("allowTrackingCookies", this.state.allowTrackingCookies, { path: "/", maxAge: 1209600 })
        if (this.state.allowTrackingCookies) {
            this.onAcceptTracking()
        }
        this.cookies.set("allowEmbedCookies", this.state.allowEmbedCookies, { path: "/", maxAge: 1209600 })
        this.cookies.set("presentedCookieConsent", true, { path: "/", maxAge: 1209600 })
        this.props.hasNoUnsafedCookieChanges()
        this.setState({ hasShownCookieConsent: true })
    }

    render() {
        return (
            <div className="gdpr-wrapper">
                {!this.state.hasShownCookieConsent && 
                    <Modal headline="Unsere Webseite benutzt Cookies" disableClose={true} needsAppearance>
                        {!this.state.editCookies && 
                            <p className="gdpr-description">Wir verwenden Cookies, um Inhalte und Anzeigen zu personalisieren, Funktionen für soziale Medien anbieten zu können und die Zugriffe auf unsere Website zu analysieren. Außerdem geben wir Informationen zu deiner Verwendung unserer Website an unsere Partner für soziale Medien, Werbung und Analysen weiter. Unsere Partner führen diese Informationen möglicherweise mit weiteren Daten zusammen, die du ihnen bereitgestellt hast oder die sie im Rahmen deiner Nutzung der Dienste gesammelt haben. Du kannst deine Auswahl jederzeit in unserer Datenschutz-Erklärung ändern.</p>
                        }
                        {this.state.editCookies && 
                            <div className="gdpr-settings">
                                <div className="row">
                                    <div className="description">
                                        <label>Technisch notwendig</label>
                                        <p>Diese Cookies benötigen wir, um die Funktionalität unserer Webseite ermöglichen zu können.</p>
                                    </div>
                                    <div className="always-on">Immer an</div>
                                </div>
                                <div className="row">
                                    <div className="description">
                                        <label>Marketing</label>
                                        <p>Diese Cookies nutzen wir, um digitales Marketing und die Auswertung von Werbekampagnen durchführen zu können.</p>
                                    </div>
                                    <Switch title="Marketing" isEnabled={this.state.allowMarketingCookies} onEnabled={this.changeMarketingCookies} onDisabled={this.changeMarketingCookies} />
                                </div>
                                <div className="row">
                                <div className="description">
                                        <label>Analyse</label>
                                        <p>Wir nutzen Diese Cookies um die Nutzung unserer Internet-Seite besser verstehen und verbessern zu können.</p>
                                    </div>
                                    <Switch title="Analyse" isEnabled={this.state.allowTrackingCookies} onEnabled={this.changeTrackingCookies} onDisabled={this.changeTrackingCookies} />
                                </div>
                                <div className="row">
                                <div className="description">
                                        <label>Social Media Einbettungen</label>
                                        <p>An einigen Stellen setzen wir Inhalte von sozialen Netzwerken ein. Mit dieser Auswahl werden diese automatisch geladen.</p>
                                    </div>
                                    <Switch title="Social Media Einbettungen" isEnabled={this.state.allowEmbedCookies} onEnabled={this.changeEmbedCookies} onDisabled={this.changeEmbedCookies} />
                                </div>
                                <div className="gdpr-actions">
                                    <div className="secondary-options">
                                        <Button type="unforced" to="#" action={this.disableAllCookies} title="Ablehnen" label="Ablehnen" />
                                    </div>
                                    <Button type="primary" to="#" action={this.saveConsent} title="Auswahl akzeptieren" label="Auswahl akzeptieren" />
                                </div>
                            </div>
                        }
                        {!this.state.editCookies && 
                            <div className="gdpr-actions">
                                <div className="secondary-options">
                                    <Button type="unforced" to="#" action={this.disableAllCookies} title="Ablehnen" label="Ablehnen" />
                                    <Button type="unforced" to="#" action={this.selectCookies} title="Anpassen" label="Anpassen" />
                                </div>
                                <Button type="primary" to="#" action={this.acceptAllCookies} title="Alle akzeptieren" label="Alle akzeptieren" />
                            </div>
                        }
                    </Modal>
                }
            </div>
        )
    }
}  
  

export default connect(null, { enableMarketingCookies, enableEmbedCookies, enableTrackingCookies, disableMarketingCookies, disableEmbedCookies, disableTrackingCookies, hasUnsafedCookieChanges, hasNoUnsafedCookieChanges, enableBasicCookies, disableBasicCookies })(withCookies(CookieConsent))